export const InterestedEmail = params => {
    new URLSearchParams(localStorage.getItem('url_query'))
        .forEach((v, k) => /utm_/.test(k) && (params[`${k.toLowerCase()}`] = v))

    return `
    <html>
        <head>
            <meta charset="UTF-8">
        </head> 
        <body> 
            <span class='CodTipoDocumentoEVOLTA' hidden="true">1</span><br/> 
            <span>Nombres: </span> <span class='NombreEVOLTA'>${params.fname}</span><br/> 
            <span>Apellidos: </span> <span class='ApellidoEVOLTA'>${params.lname}</span><br/> 
            <span>Correo: </span> <span class='CorreoEVOLTA'>${params.email}</span><br/> 
            <span>Telefono: </span> <span class='TelefonoEVOLTA'>${params.telephone}</span><br/> 
            <span>Comentarios: </span> <span class='ComentarioEVOLTA'>${params.observation || ""}</span><br/>
            <span>Utm Source: </span> <span class='UtmSourceEVOLTA'>${params.utm_source || ""}</span><br/> 
            <span>Utm Medium: </span> <span class='UtmMediumEVOLTA'>${params.utm_medium || ""}</span><br/> 
            <span>Utm Campaign: </span> <span class='UtmCampaignEVOLTA'>${params.utm_campaign || ""}</span><br/> 
            <span>Utm Content: </span> <span class='UtmContentEVOLTA'>${params.utm_content || ""}</span><br/> 
            <span>Utm Term: </span> <span class='UtmTermEVOLTA'>${params.utm_term || ""}</span><br/>
        </body>
    </html>`
}