import React, { Component } from 'react'
import "./modal.scss"
import $ from 'jquery'
export default class Modal extends Component {
    constructor(props){
        super(props)
        this.state = {
            active : false
        }
        this.hideModal = this.hideModal.bind(this)
    }
    hideModal(event){
        let type = event.target.dataset.type;
        if(type == "modal"){
            $(".content-Modal-error").removeClass("active")
        }
    }
    render() {
        return (
            <div  className={`content-Modal-error`} 
                     onClick={this.hideModal.bind(this)}
                     data-type="modal"
                     >
                 {this.props.children}
            </div>
        )
    }
}
