import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import OwlCarousel from "react-owl-carousel2";
import "lightgallery/dist/css/lightgallery.css";
import "lightgallery/dist/js/lightgallery";
import "lightgallery/dist/js/lightgallery-all";
import "./acordeon.scss";
import $ from "jquery";

const options = {
  items: 2,
  center: true,
  rewind: false,
  loop: false,
  nav: true,
  margin: 10,
  smartSpeed: 700,
  dots: true,
  autoWidth: true,
  navText: [
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.36 27.07"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="14.36 25.07 2.83 13.53 14.36 2" fill="none" stroke="#00878a" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/></g></g></svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.36 27.07"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="2 2 13.53 13.53 2 25.07" fill="none" stroke="#00878a" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/></g></g></svg>',
  ],
};
const events = {
  onChanged: function (e) {},
};
const configModal = {
  selector: ".diap",
  thumbnail: true,
  animateThumb: false,
  showThumbByDefault: false,
  subHtmlSelectorRelative: true,
  rotate: false,
  actualSize: false,
  download: true,
  pinterest: false,
  googlePlus: false,
  twitter: true,
  enableDrag: true,
};
export default class AcordeonInicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "panel5",
    };
  }
  componentDidMount() {
    $(".MuiTypography-body1").lightGallery(configModal);
  }
  handleChange = (panel) => (event, newExpanded) => {
    this.setState({ expanded: newExpanded ? panel : false });
  };

  render() {
    return (
      <div className="accordeon-screen">
        <Accordion
          expanded={this.state.expanded === "panel5"}
          square
          onChange={this.handleChange("panel5", this)}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              Tipologías
              <div className="content-icon acord">
                <div className="icon-acordeon down"></div>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="background-color"></div>
              <OwlCarousel
                ref="car"
                options={options}
                events={events}
                className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag"
                id="carousel-tipologia"
              >
                {this.props.sliders.tipologia.map((e, index) => {
                  return (
                    <div
                      className="diap"
                      key={"slider" + index}
                      data-src={e.imgDesktop}
                      href={e.imgDesktop}
                      data-exthumbimage={e.imgDesktop}
                      data-sub-html={"#comun-" + index}
                    >
                      <div className="content-img">
                        <div className="lupa">
                          <div className="icon-lupa"></div>
                        </div>
                        <img src={e.img} alt={"tipologia" + index}></img>
                      </div>
                      {/* <div className="detail">
                                                    <h2 className="title">{e.title}</h2>
                                                    <p className="paragraph">{e.paragraph}</p>
                                                </div> */}
                    </div>
                  );
                })}
              </OwlCarousel>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={this.state.expanded === "panel2"}
          onChange={this.handleChange("panel2", this)}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              Proyecto
              <div className="content-icon acord">
                <div className="icon-acordeon down"></div>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="background-color"></div>
              <OwlCarousel
                ref="car"
                options={options}
                events={events}
                className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag"
                id="carousel-interiores"
              >
                {this.props.sliders.sitio.map((e, index) => {
                  return (
                    <div
                      className="diap"
                      key={"slider-sitio" + index}
                      data-src={e.img}
                      href={e.img}
                      data-exthumbimage={e.img}
                      data-sub-html={"#sitio-" + index}
                    >
                      <div className="content-img">
                        <div className="lupa">
                          <div className="icon-lupa"></div>
                        </div>
                        <img src={e.img} alt={e.title}></img>
                        <div id={"office" + index}>
                          <h4>{e.title}</h4>
                        </div>
                      </div>
                      {/* <div className="detail">
                                                    <h2 className="title">{e.title}</h2>
                                                    <p className="paragraph">{e.paragraph}</p>
                                                </div> */}
                    </div>
                  );
                })}
              </OwlCarousel>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={this.state.expanded === "panel1"}
          square
          onChange={this.handleChange("panel1", this)}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Ubicación
              <div className="content-icon acord">
                <div className="icon-acordeon down"></div>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="background-color"></div>
              <div
                className="diap"
                data-src={this.props.sliders.ubicacion[0].img}
                href={this.props.sliders.ubicacion[0].img}
                data-exthumbimage={this.props.sliders.ubicacion[0].img}
                data-sub-html={"#ubicacion-" + 1}
              >
                <div className="content-img">
                  <div className="lupa">
                    <div className="icon-lupa"></div>
                  </div>
                  <img
                    src={this.props.sliders.ubicacion[0].img}
                    alt={this.props.sliders.ubicacion[0].title}
                  ></img>
                  <div id={"ubicacion" + 1}>
                    <h4>{this.props.sliders.ubicacion[0].title}</h4>
                  </div>
                </div>
                {/* <div className="detail">
                                    <h2 className="title">{e.title}</h2>
                                    <p className="paragraph">{e.paragraph}</p>
                                </div> */}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={this.state.expanded === "panel3"}
          square
          onChange={this.handleChange("panel3", this)}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              Áreas comunes
              <div className="content-icon acord">
                <div className="icon-acordeon down"></div>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="background-color"></div>
              <OwlCarousel
                ref="car"
                options={options}
                events={events}
                className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag"
                id="carousel-interiores"
              >
                {this.props.sliders.comun.map((e, index) => {
                  return (
                    <div
                      className="diap"
                      key={"slider-comun" + index}
                      data-src={e.imgDesktop}
                      href={e.imgDesktop}
                      data-exthumbimage={e.imgDesktop}
                      data-sub-html={"#comun-" + index}
                    >
                      <div className="content-img">
                        <div className="lupa">
                          <div className="icon-lupa"></div>
                        </div>
                        <img src={e.img} alt={e.title}></img>
                        <div id={"comun" + index}>
                          <h4>{e.title}</h4>
                        </div>
                      </div>
                      {/* <div className="detail">
                                                    <h2 className="title">{e.title}</h2>
                                                    <p className="paragraph">{e.paragraph}</p>
                                                </div> */}
                    </div>
                  );
                })}
              </OwlCarousel>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={this.state.expanded === "panel4"}
          square
          onChange={this.handleChange("panel4", this)}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              Interiores
              <div className="content-icon acord">
                <div className="icon-acordeon down"></div>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="background-color"></div>
              <OwlCarousel
                ref="car"
                options={options}
                events={events}
                className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag"
                id="carousel-interiores"
              >
                {this.props.sliders.interior.map((e, index) => {
                  return (
                    <div
                      className="diap"
                      key={"slider-interior" + index}
                      data-src={e.imgDesktop}
                      href={e.imgDesktop}
                      data-exthumbimage={e.imgDesktop}
                      data-sub-html={"#comun-" + index}
                    >
                      <div className="content-img">
                        <div className="lupa">
                          <div className="icon-lupa"></div>
                        </div>
                        <img src={e.img} alt={e.title}></img>
                        <div id={"comun" + index}>
                          <h4>{e.title}</h4>
                        </div>
                      </div>
                      {/* <div className="detail">
                                                    <h2 className="title">{e.title}</h2>
                                                    <p className="paragraph">{e.paragraph}</p>
                                                </div> */}
                    </div>
                  );
                })}
              </OwlCarousel>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}
