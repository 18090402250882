import React, { Component ,Suspense} from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import MediaQuery from 'react-responsive';
import $ from 'jquery'
import Inicio from './pages/inicio/inicio';
import Navbar from './components/navbar/navbar';
import Popup from './components/popup/popup';
import inicio from './properties/properties'
import Footer from './components/footer/footer'
import Whatsapp from './components/whatsapp/whatsapp' 
import CodigoDeProteccion from './pages/AtencionCliente/CodigoDeProteccion';
import DataGlobal from './components/data/data'
import Politicasdeprivacidad from './pages/politicasdeprivacidad/politicasdeprivacidad';
import { WorkProgresss } from './pages/workProgress/workProgress';
export default class Routes extends Component {
    constructor(props){
        super(props)
        this.state = {
            modalForm:true,
        }
    }
    render() {
        return (
            <div className="Main-pages">
                    <Router>
                        {/* <Loading/> */}
                        <DataGlobal.Provider>
                        <Navbar></Navbar>
                        <Switch> 
                            <Route exact path="/">
                                {/* <Popup></Popup> */}
                                <Inicio properties={inicio}></Inicio>
                                <Whatsapp></Whatsapp>
                            </Route>
                            <Route exact path="/avance-de-obra">
                                {/* <Popup></Popup> */}
                                <WorkProgresss/>
                            </Route>
                            <Route exact path="/codigo-de-proteccion-al-consumidor">
                                <CodigoDeProteccion></CodigoDeProteccion>
                            </Route>
                            <Route exact path="/politicas">
                                <Politicasdeprivacidad></Politicasdeprivacidad>
                            </Route>
                            <Route exact path="/libro-de-reclamaciones">
                                <Politicasdeprivacidad></Politicasdeprivacidad>
                            </Route>
                            {/* <Redirect to="/"/> */}
                        </Switch>
                        <Footer></Footer>
                        </DataGlobal.Provider>
                    </Router>
            </div>
        )
    }
}
