import React, { Component } from 'react'
import './footer.scss'

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="content-links">
                    <a href="https://padova.pe/" target="_blank" className="link-padova" data-gtm="brand-link"><img className="brand" src={require("../../Assets/images/iconos/lomas-right.svg").default}></img></a>
                    <div className="link link-place">
                        <div className="icon place">
                        </div>
                        <a href="https://goo.gl/maps/Ajtvy7g6hWL66jDr9" target="_blank" className="column">
                        Av. Universitaria cdra. 15<span className="info"> Carabayllo</span></a>
                        </div>
                    <div className="column">
                        <a href="tel:(+51) 998 157 192" target="_blank" className="link">
                            <div className="icon whatsapp"></div><span className="text-link">998 157 192</span>
                        </a>
                        <a href="tel:(+51) 981 277 975" target="_blank" className="link">
                            <div className="icon whatsapp"></div><span className="text-link">981 277 975</span>
                        </a>
                    </div>
                    <a href="mailto:servicioalcliente@padovasac.com" target="_blank" className="link"><div className="icon email"></div><span className="text-link">servicioalcliente@padovasac.com</span></a>
                </div>
                <div className="content-footer ">
                    <div className='container'>
                        <div className="contain">
                            <div className='container-links'>
                                <span className="link white paragraph">
                                    <a href="/codigo-de-proteccion-al-consumidor" className="white"> Código de protección al consumidor</a>
                                    <a href="/politicas" className="white">Políticas de privacidad</a>
                                </span>
                                <a className='booking' href='/libro-de-reclamaciones' target='_blank'>
                                    <img className='' src={require('../../Assets/images/iconos/libro-de-reclamaciones.jpg')} ></img>
                                </a>
                            </div>
                            <div className="item">
                                <span className="white sub-title paragraph">©{ new Date().getFullYear()} Lomas de Carabayllo. Todos los derechos reservados.<span className="line"></span>
                                 Creado con ♡ por <a className="white bold" href="https://www.formulaperu.com/" target="_blank">Fórmula</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
