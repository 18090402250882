import axios from "axios";

export class MailService {
    constructor() {
        this.mailgun = axios.create({
            baseURL: "https://n8n.formulaperu.com/webhook/",
            auth: {
                username: 'padova',
                password: '9f0b0f9cujc9sjcs9w0f0bjskk'
            }
        })
        this.payload = new FormData()
    }

    from(from) {
        this.payload.append("from", from)
        return this
    }

    to(to) {
        this.payload.append("to", to)
        return this
    }

    subject(subject) {
        this.payload.append("subject", subject)
        return this
    }

    html(html) {
        this.payload.append("html", html)
        return this
    }

    attachment(attachment) {
        this.payload.append("attachment", attachment)
        return this
    }

    async send() {
        return this.mailgun.post('6e992e7e-4c5b-41b2-a7d4-4d0b7bf09c54', this.payload)
    }
}