const inicio = {
    sitio: [
        // {img: require("../Assets/images/slider/Proyecto/1-proyecto.png")},
        {img: require("../Assets/images/slider/Proyecto/9-proyecto.png")},
        {img: require("../Assets/images/slider/Proyecto/8-proyecto.png")},
        {img: require("../Assets/images/slider/Proyecto/2-proyecto.png")},
        {img: require("../Assets/images/slider/Proyecto/3-proyecto.png")},
        {img: require("../Assets/images/slider/Proyecto/render.png")},
        {img: require("../Assets/images/slider/Proyecto/4-proyecto.png")},
        {img: require("../Assets/images/slider/Proyecto/7-proyecto.png")},

     ],

    itemsMovil:[
        {img:require("../Assets/images/inicio/atributos/target/target-ascensor.jpg"),title:"Solo 5 pisos por torre"},
        {img:require("../Assets/images/inicio/atributos/target/target-pisos.jpg"),title:"Solo 5 pisos por torre"},
        {img:require("../Assets/images/inicio/atributos/target/target-condominio.png"),title:"Solo 5 pisos por torre"},
        {img:require("../Assets/images/inicio/atributos/target/target-conectividad.jpg"),title:"Solo 5 pisos por torre"},
        {img:require("../Assets/images/inicio/atributos/target/target-areas-comunes.jpg"),title:"Solo 5 pisos por torre"},
        {img:require("../Assets/images/inicio/atributos/target/target-estacion.jpg"),title:"Solo 5 pisos por torre"},
    ],
    items:[
        {img:require("../Assets/images/iconos/edificio.svg"),title:"Solo 5 pisos por torre"},
        {img:require("../Assets/images/iconos/ping-pong.svg"),title:"Áreas comunes"},
        {img:require("../Assets/images/iconos/ascensor.svg"),title:"Ascensor en cada torre"},
        {img:require("../Assets/images/iconos/maps.svg"),title:"Fácil acceso",subtitle:"(Av. Universitaria)"},
        {img:require("../Assets/images/iconos/policia.svg"),title:"Vigilancia 24 horas"},
        {img:require("../Assets/images/iconos/icon-estacion.svg"),title:"Estación Chimpú Ocllo"},
    ],
    comun: [
        {
            img: require("../Assets/images/slider/Areas-comunes/cancha.png"),
            imgDesktop: require("../Assets/images/slider/Areas-comunes/web/cancha.png"),
            title: "",
            paragraph: ""
        },
        {
            img: require("../Assets/images/slider/Areas-comunes/web/futbol_de_mesa.png"),
            imgDesktop: require("../Assets/images/slider/Areas-comunes/web/futbol_de_mesa.png"),
            title: "",
            paragraph: ""
        },
        {
            img: require("../Assets/images/slider/Areas-comunes/web/juego_de_ninos.png"),
            imgDesktop: require("../Assets/images/slider/Areas-comunes/web/juego_de_ninos.png"),
            title: "",
            paragraph: ""
        },
        {
            img: require("../Assets/images/slider/Areas-comunes/web/pingpong.png"),
            imgDesktop: require("../Assets/images/slider/Areas-comunes/web/pingpong.png"),
            title: "",
            paragraph: ""
        },
    ],
    interior: [
        {
            img: require("../Assets/images/slider/Interiores/cocina.png"),
            imgDesktop: require("../Assets/images/slider/Interiores/web/cocina.png"),
            title: "",
            paragraph: ""
        },
        {
            img: require("../Assets/images/slider/Interiores/dormitorio.png"),
            imgDesktop: require("../Assets/images/slider/Interiores/web/dormitorio.png"),
            title: "",
            paragraph: ""
        },
        {
            img: require("../Assets/images/slider/Interiores/dormitorio-principal.png"),
            imgDesktop: require("../Assets/images/slider/Interiores/web/dormitorio-principal.png"),
            title: "",
            paragraph: ""
        },
        {
            img: require("../Assets/images/slider/Interiores/sala-comedor.png"),
            imgDesktop: require("../Assets/images/slider/Interiores/web/sala-comedor.png"),
            title: "",
            paragraph: ""
        },
        
    ],
    tipologia: [
        {
            img: require("../Assets/images/slider/Tipologias/sliders-lomas-tipologia-2dorms.png"),
            imgDesktop: require("../Assets/images/slider/Tipologias/sliders-lomas-tipologia-2dorms.png"),
            title: "Tipologías",
            paragraph: ""
        },
        {
            img: require("../Assets/images/slider/Tipologias/sliders-lomas-tipologia-3dorms.png"),
            imgDesktop: require("../Assets/images/slider/Tipologias/sliders-lomas-tipologia-3dorms.png"),
            title: "Tipologías",
            paragraph: ""
        },
        {
            img: require("../Assets/images/slider/Tipologias/sliders-lomas-tipologia-4dorms.png"),
            imgDesktop: require("../Assets/images/slider/Tipologias/sliders-lomas-tipologia-4dorms.png"),
            title: "Tipologías",
            paragraph: ""
        },
        
    ],
    ubicacion:[{
        img:require("../Assets/images/slider/Ubicacion/sliders-lomas-mapa.png"),
    }]
}

export default inicio