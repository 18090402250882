import React, { Component } from 'react'

export default class ModalSuccess extends Component {
    constructor(props){
        super(props)
        this.state = {
            toggle:false,
            message:false,
            // url:"https://wa.link/tvt9q1",
            linkPhone:"tel:(+51)998157192",
            close:true,
        }
        this.close = this.close.bind(this)
    }
    componentDidMount(){
        if (window.matchMedia("(min-width: 740px)").matches) {
            // this.setState({
            //     url:"https://web.whatsapp.com/send?phone=51981277975&text=Hola,%20deseo%20informaci%C3%B3n%20sobre%20el%20proyecto%20Lomas%20de%20Carabayllo",
            // })
        }
    }
    close(e){
        let modal = document.querySelector(".content-whatsapp.modal")
        modal.classList.remove("active")
        modal.classList.add("close")
    }
    render() {
        return (
            <div className={`content-whatsapp modal`} data-gtm="form-modal" onClick={(e)=>{this.close(e)}} data-close="true">
                <div className={`screen-movil active`}></div>
                 <div className={`content-form active message`}>
                    <div className="close" onClick={()=>{this.close()}}>
                        <div className="icon-close"></div>
                    </div>
                    <div className="message-whatsapp active">
                            <span className="title-3">
                                MUCHAS GRACIAS
                            </span>
                            <span className="title-4">
                                TUS DATOS YA FUERON REGISTRADOS
                            </span>
                            <span className="title">
                                Comunícate con un
                            </span>
                            <span className="title-2">
                                asesor aquí
                            </span>
                            <a href="" target="_blank" className="content-icon-message b-greenLigth --whatsapp">
                                <div className="icon-popup icon-whatsapp">
                                </div>
                                <span className="title-1">Whatsapp</span>
                            </a>
                            <a href="" target="_blank" className="content-icon-message b-third --phone">
                                <div className="icon-popup phone-link">
                                </div>
                                <span className="title-2"></span>
                            </a>
                        </div>
                 </div>
            </div>
        )
    }
}
