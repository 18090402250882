import { useState } from 'react'
import $ from 'jquery';
import './stage.scss'
import MediaQuery from 'react-responsive';
import Slider from "react-slick";
import { settingsStages } from '../../pages/inicio/config';
export default function Stage(props){
    const [show, showHover] = useState(true)
    const [hover, setHover] = useState(false)
    const showImage = (e) => {
        if(show == true){
            $('html, body').animate({
                scrollTop: $(".overflow").offset().top - 300
            }, 700);
        }else{
            $('html, body').animate({
                scrollTop: $(".text-center.title.mb-4").offset().top - 300
            }, 700);
        }
        showHover(!show)
        // $()
    }
    const activeHover = () => {
        setHover(true)
    }
    return(
        <section className='container mx-auto section-stage'>
            <img className="cloud-1" src={require("../../Assets/images/iconos/cloud-1.png")}></img>
            <img className="start-1" src={require("../../Assets/images/iconos/starts-1.png")}></img>
            <h2 className="text-center title semibold mb-4">
                <img className="title-left" src={require("../../Assets/images/iconos/title-left.png")}></img>
                <img className="title-right" src={require("../../Assets/images/iconos/title-right.png")}></img>
                ¡ASÍ CRECEMOS EN LOMAS DE CARABAYLLO!
               
            </h2>
            <div className='content-stage'>
                <MediaQuery query="(min-width:500px)">
                    <Stages></Stages>
                </MediaQuery>
                <MediaQuery query="(max-width:499px)">
                    <Slider {...settingsStages}  className="Slider-characteristic">
                    <div className='stage'>
                        <div className='head-stage bg1'>
                            <div className='checked'>
                                <img className='' src={require('../../Assets/images/iconos/check-orange.png')} ></img>
                            </div>
                            <div className='bold num'>
                                100%
                            </div>
                            <span className='bold sub-title'>ENTREGADO</span>
                        </div>
                        <div className='c1 stage-step'>
                            <span className='medium'>
                                ETAPA 1
                            </span>
                        </div>
                    </div>
                    <div className='stage'>
                        <div className='head-stage bg2'>
                            <div className='checked'>
                                <img className='' src={require('../../Assets/images/iconos/icon-check-green.png')} ></img>
                            </div>
                            <div className='bold num'>
                                100%
                            </div>
                            <span className='bold sub-title'>ENTREGADO</span>
                        </div>
                        <div className=' c2 stage-step'>
                            <span className='medium'>
                                ETAPA 2
                            </span>
                        </div>
                    </div>
                    <div className='stage'>
                        <div className='head-stage bg3'>
                            <div className='checked'>
                                <img className='' src={require('../../Assets/images/iconos/icon-start.png')} ></img>
                            </div>
                            <div className='bold num'>
                                100%
                            </div>
                            <span className='bold sub-title'>ENTREGADO</span>
                        </div>
                        <div className='c3 stage-step'>
                            <span className='medium'>
                                ETAPA 3
                            </span>
                        </div>
                    </div>
                    <div className='stage'>
                        <div className='head-stage bg4'>
                            <div className='checked'>
                                <img className='' src={require('../../Assets/images/iconos/icon-stage-4.png')} ></img>
                            </div>
                            <span className='sell bold'>
                                {/* <img className='border' src={require('../../Assets/images/iconos/border.png')} ></img> */}
                                INICIO 
                                <span className='bold'>DE OBRA</span>
                            </span>
                        </div>
                        <div className='c4 stage-step'>
                            <span className='medium'>
                                ETAPA 4
                            </span>
                        </div>
                    </div>
                    </Slider>
                </MediaQuery>
            </div>
           
            <div className='container'>
                <div className={`more-information button ${show == true ? 'active':'inactive'}`} onClick={()=>{showImage()}}>
                    MÁS INFORMACIÓN
                </div>
                <div className={`img-project ${show == true ? '':'active'}`}>
                    {/* <div class="shadow"></div> */}
                    <div className={`content-img ${hover ? 'active':''}`} onClick={()=>{activeHover()}}>
                        <div className='overflow'>
                            <div className='d-flex'>
                                <img className='' src={require('../../Assets/images/inicio/slider/lomas-proyecto.png')} ></img>
                            </div>
                        </div>
                        <div className='less more-information button active' onClick={()=>{showImage()}}>
                            VER MENOS
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


const Stages = ()=>{
    return (
        <>
        <div className='stage'>
            <div className='head-stage bg1'>
                <div className='checked'>
                    <img className='' src={require('../../Assets/images/iconos/check-orange.png')} ></img>
                </div>
                <div className='bold num'>
                    100%
                </div>
                <span className='bold sub-title'>ENTREGADO</span>
            </div>
            <div className='c1 stage-step'>
                <span className='medium'>
                    ETAPA 1
                </span>
            </div>
        </div>
        <div className='stage'>
            <div className='head-stage bg2'>
                <div className='checked'>
                    <img className='' src={require('../../Assets/images/iconos/icon-check-green.png')} ></img>
                </div>
                <div className='bold num'>
                    100%
                </div>
                <span className='bold sub-title'>ENTREGADO</span>
            </div>
            <div className=' c2 stage-step'>
                <span className='medium'>
                    ETAPA 2
                </span>
            </div>
        </div>
        <div className='stage'>
            <div className='head-stage bg3'>
                <div className='checked'>
                    <img className='' src={require('../../Assets/images/iconos/icon-start.png')} ></img>
                </div>
                <div className='bold num'>
                    100%
                </div>
                <span className='bold sub-title'>ENTREGADO</span>
            </div>
            <div className='c3 stage-step'>
                <span className='medium'>
                    ETAPA 3
                </span>
            </div>
        </div>
        <div className='stage'>
            <div className='head-stage bg4'>
                <div className='checked'>
                    <img className='' src={require('../../Assets/images/iconos/icon-stage-4.png')} ></img>
                </div>
                <span className='sell bold'>
                    {/* <img className='border' src={require('../../Assets/images/iconos/border.png')} ></img> */}
                    EN
                    <span className='bold'>CONSTRUCCIÓN</span>
                </span>
            </div>
            <div className='c4 stage-step'>
                <span className='medium'>
                    ETAPA 4
                </span>
            </div>
        </div>
        </>
    )
}