export const settingsStages = {
    dots: false,
    centerMode: true,
    speed: 100,
    variableWidth: true,
    infinite:true,
    slidesToShow: 2,
    slidesToScroll: 1,
    margin: 40,
    beforeChange: function(current, next){
        
    }
}