import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import "./Assets/scss/index.scss";
import Routes from "./routes";
import $ from "jquery";

window.$ = $
window.jQuery = window.$

ReactDOM.render(<Routes/>, document.getElementById('root'));

if (window.location.search) localStorage.setItem('url_query', window.location.search)

if(!sessionStorage.getItem("seller_phone")) {
    const phones = [
        ["landing@lomasdecarabayllo.pe", 998157192],
        // ["landing2@lomasdecarabayllo.pe", 981277975]
    ]

    sessionStorage.setItem("seller_phone", phones[Math.floor(Math.random() * phones.length)])
}
serviceWorker.unregister();
  