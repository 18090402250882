import React, { Component } from 'react'
// import './formulario.scss'
import {  FormContainer, checkableBoolProps, setInputProps } from '../common/forms/Form'
import { BaseValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import { InterestedEmail } from "./../Mails/InterestedEmail.js";
import Swal from 'sweetalert2'
import axios from 'axios'
import { MailService } from '../services/MailService';
import $ from 'jquery'

export default class Whatsapp extends Component {
    constructor(props){
        super(props)
        this.state = {
            toggle:false,
            message:false,
            // url:"https://wa.link/vixvj3",
            // linkPhone:"tel:998157192",
        }
        this.submitForm = this.submitForm.bind(this)
        this.closeMessage = this.closeMessage.bind(this)
        this.initWhatsApp = this.initWhatsApp.bind(this)
        this.mailService = new MailService()
       
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        terms: false
    }
    
    componentDidMount(){
        if (window.matchMedia("(min-width: 740px)").matches) {
            // this.setState({
            //     url:"https://web.whatsapp.com/send?phone=51998157192&text=Hola,%20deseo%20informaci%C3%B3n%20sobre%20el%20proyecto%20Lomas%20de%20Carabayllo",
            // })
        }
    }
    submitForm(values, { setSubmitting, resetForm }) {
        const requests = []
        const form = document.querySelector("form.wsp-modal")
        const [fromEmail, seller_phone] = sessionStorage.getItem("seller_phone").split(",")

        this.mailService.from(`Web Lomas <${fromEmail}>`)
            .to("padova_lomas_carabayllo@evolta.pe")
            .subject("Evolta lead")
            .html(InterestedEmail(values))

        new URLSearchParams(localStorage.getItem('url_query'))
                .forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v))
                
        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "14rEV56M4ujlxi-l3ABAoKCYIww_ejcuMhVRjaFxljEo",
                "range": `Web!A:E`,
                "values": [[
                    new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' }), values.fname, values.lname, values.email, values.telephone, seller_phone,
                    values.utm_source, values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term]],
            }, {headers: {"X-Requested-With": "XMLHttpRequest"}}
        )
    
        // this.mailService.send()
        axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
            "environment": "mirano",
            "lead": {
                "IdProyecto": 65,
                "Nombres": values.fname,
                "Apellidos": values.lname,
                "Correo": values.email,
                "Celular": values.telephone,
                "Comentario": values.observation,
                "url_query": localStorage.getItem('url_query')
            }
        })
        .then(r => {
                form.reset()
                resetForm()

                this.setState({
                    message: true,
                    url: `https://web.whatsapp.com/send?phone=51${seller_phone}&text=Hola,%20deseo%20informaci%C3%B3n%20sobre%20el%20proyecto%20Lomas%20de%20Carabayllo`,
                    linkPhone: `tel:${seller_phone}`,
                    phoneFormated: seller_phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
                })

                window.dataLayer && window.dataLayer.push({'event': 'LeadWhatsApp'})
                // alert(1)
                setSubmitting(false)
            })
            .catch(e => {
                $(".content-Modal-error").addClass("active")
                // alert(2)
                setSubmitting(false)
            })
    }
    change(e){
        if(e.target.dataset.toggle == "btn"){
            this.setState({toggle: !this.state.toggle})
        }
    }
    closeMessage(){
        $(".content-img-message").addClass("none")
    }
    initWhatsApp(){
        
        this.setState({toggle:false,message:false})
    }
    render() {
        return (
            <FormContainer
            initialValues={this.initialValues}
            validationSchema={BaseValidatonSchema}
            onSubmit={this.submitForm}>
            {form => {const {handleSubmit} = form;
            return(
            <form  className={`content-whatsapp wsp-modal ${this.props.page} ${(this.state.message) ? "message " : ""}`} data-gtm="form-wsp-modal" onSubmit={handleSubmit} onClick={this.change.bind(this)}>
                <div className={"content-icon-toggle "+(this.state.toggle ?  "active" : "inactive")} data-toggle="btn" data-gtm="whatsapp-button">
                    <div className="content-img-message">
                        <img className="img-message" data-toggle="btn" src={require("../../Assets/images/iconos/message-whatsapp.png")}></img>
                        <div className="close" data-toggle="btn" onClick={()=>{this.closeMessage()}}>
                            <div className="icon-close" ></div>
                        </div>
                    </div>
                    <img className="img-whatsapp" data-toggle="btn" src={require("../../Assets/images/inicio/botton-whatsapp.png")}></img>
                    <div className="static-content" data-toggle="btn">
                        {/* <img className="img-close" data-toggle="btn" src={require("../../Assets/images/iconos/close-padova.png")}></img> */}
                        
                        <div className="content-icon" data-toggle="btn">
                            <div className={"icon "+ (this.state.toggle ?  "icon-close" : "icon-close")}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`screen-movil ${(this.state.toggle) ? "active":""}`} onClick={()=>{this.change.bind(this)}} data-toggle="btn">
                    <div className={`content-form ${(this.state.message) ? "message " : ""}`+ (this.state.toggle ? "active " : "") }>
                    <div className="formulario">
                        <h2 className="title">COMPÁRTENOS TUS DATOS</h2>
                        <p className="paragraph">Y NUESTROS ASESORES TE</p>
                        <p className="paragraph paragraph-2">CONTACTARÁN A LA BREVEDAD</p>
                        <div className="content-input">
                            <input
                                {...setInputProps("fname", "input", form)}
                                placeholder="Nombre"
                            ></input>
                            <div className="icon user"></div>
                        </div>
                        <div className="content-input">
                            <input
                                 {...setInputProps("lname", "input", form)}
                                placeholder="Apellidos"
                            ></input>
                            <div className="icon user"></div>
                        </div>
                        <div className="content-input">
                            <input
                                {...setInputProps("telephone", "input", form)}
                                placeholder="Celular"
                            ></input>
                            <div className="icon phone"></div>
                        </div>
                        <div className="content-input">
                            <input
                               {...setInputProps("email", "input", form)}
                                placeholder="Correo"
                            ></input>
                            <div className="icon mail"></div>
                        </div>
                        
                        <div className="content-input terms">
                            <input
                               {...checkableBoolProps("terms", "", form)}
                                type="checkbox"
                                id="term-whatsapp"
                                >
                            </input>
                            <label htmlFor="term-whatsapp" className="button-term"><div className="checked"></div></label>
                            <label className={"text-term"} htmlFor="term-whatsapp">Acepto <strong><a target="_blank" href="Politica-de-privacidad-de-datos.pdf">términos y condiciones</a></strong></label>
                        </div>
                        <div className="content-input">
                            <button className="contactar" type="submit" disabled={form.isSubmitting}>{form.isSubmitting ? "ENVIANDO..." : "CONTACTAR"}</button>
                        </div>
                    </div>
                    <div className="message-whatsapp">
                        <div className="close" onClick={()=>{this.initWhatsApp()}}>
                            <div className="icon-close"></div>
                        </div>
                        <span className="title-3">
                            ¡MUCHAS GRACIAS!
                        </span>
                        <span className="title-4">
                            TUS DATOS YA FUERON REGISTRADOS
                        </span>
                        <span className="title">
                            Comunícate con un
                        </span>
                        <span className="title-2">
                            asesor aquí
                        </span>
                        <a href={this.state.url} target="_blank" className="content-icon-message b-greenLigth">
                            <div className="icon-popup icon-whatsapp">
                            </div>
                            <span className="title-1">Whatsapp</span>
                        </a>
                        <a href={this.state.linkPhone} target="_blank" className="content-icon-message b-third">
                            <div className="icon-popup phone-link">
                            </div>
                            <span className="title-2">{this.state.phoneFormated}</span>
                        </a>
                    </div>
                </div>
                </div>
            </form>
            )
            }}
            </FormContainer>
        )
    }
}
