import React, { Component } from 'react'
import './formulario.scss'
import {  FormContainer, checkableBoolProps, setInputProps } from '../common/forms/Form'
import { BaseValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import Swal from 'sweetalert2'
import axios from 'axios'
import { InterestedEmail } from '../Mails/InterestedEmail'
import { MailService } from '../services/MailService'
import DataGlobal from '../data/data'

export default class Formulario extends Component {
    constructor(props){
        super(props)
        this.state = {
            toggle:true,
            message:false,
        }
        this.submitForm = this.submitForm.bind(this)
        this.mailService = new MailService()
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        terms: false
    }
    submitForm(values, { setSubmitting, resetForm }) {
        const requests = []
        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
        const form = document.querySelector(".formulario-inicio form")
        const [fromEmail, seller_phone] = sessionStorage.getItem("seller_phone").split(",")

        this.mailService.from(`Web Lomas <${fromEmail}>`)
            .to("padova_lomas_carabayllo@evolta.pe")
            .subject("Evolta lead")
            .html(InterestedEmail(values))
        
        new URLSearchParams(localStorage.getItem('url_query'))
                .forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v))

        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "14rEV56M4ujlxi-l3ABAoKCYIww_ejcuMhVRjaFxljEo",
                "range": `Web!A:E`,
                "values": [[date, values.fname, values.lname, values.email, values.telephone, seller_phone, values.utm_source,
                            values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term]],
            }, {headers: {"X-Requested-With": "XMLHttpRequest"}}
        )
        // this.mailService.send()
        axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
            "environment": "mirano",
            "lead": {
                "IdProyecto": 65,
                "Nombres": values.fname,
                "Apellidos": values.lname,
                "Correo": values.email,
                "Celular": values.telephone,
                "Comentario": values.observation,
                "url_query": localStorage.getItem('url_query')
            }
        })
        .then(r => {
                form.reset()
                resetForm()
                // console.log(r)
                // this.setState({message:true})
                window.dataLayer && window.dataLayer.push({'event': 'LeadFormulario'})
                
                let modal = document.querySelector(".content-whatsapp.modal")
                modal.querySelector(".--whatsapp").setAttribute("href", `https://web.whatsapp.com/send?phone=51${seller_phone}&text=Hola,%20deseo%20informaci%C3%B3n%20sobre%20el%20proyecto%20Lomas%20de%20Carabayllo`)
                modal.querySelector(".--phone").setAttribute("href", `tel:${seller_phone}`)
                modal.querySelector(".--phone .title-2").textContent = seller_phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')

                modal.classList.remove("close")
                modal.classList.add("active")
                setSubmitting(false)
            })
            .catch(e => {
                setSubmitting(false)
                let modal =  document.querySelector(".content-Modal-error")
                modal.open = true;
            })
    }
    render() {
        return (
            <DataGlobal.Consumer>
                {
                    context =>{
                        return(
                    <FormContainer
                                initialValues={this.initialValues}
                                validationSchema={BaseValidatonSchema}
                                onSubmit={this.submitForm}>
                                {form => {const {handleSubmit, isSubmitting} = form;
                                return(
                                <form className={"content-whatsapp "} onSubmit={handleSubmit}>
                                    <div className={`screen-movil ${(this.state.toggle) ? "active":""}`}>
                                        <div className={`content-form ${(this.state.message) ? "message " : ""}`+ (this.state.toggle ? "active " : "") }>
                                        <div className="formulario">
                                            <h2 className="title text-center">ENTÉRATE DE LAS</h2>
                                            <h2 className="title-2 text-center">PROMOCIONES</h2>
                                            <p className="title-3 text-center">QUE TENEMOS PARA TI</p>
                                            <div className="content-input">
                                                <input
                                                    {...setInputProps("fname", "input input-name", form)}
                                                    placeholder="Nombre"
                                                ></input>
                                                <div className="icon user"></div>
                                            </div>
                                            <div className="content-input">
                                                <input
                                                    {...setInputProps("lname", "input", form)}
                                                    placeholder="Apellidos"
                                                ></input>
                                                <div className="icon user"></div>
                                            </div>
                                            <div className="content-input">
                                                <input
                                                    {...setInputProps("telephone", "input", form)}
                                                    placeholder="Celular"
                                                ></input>
                                                <div className="icon phone"></div>
                                            </div>
                                            <div className="content-input">
                                                <input
                                                {...setInputProps("email", "input", form)}
                                                    placeholder="Correo"
                                                ></input>
                                                <div className="icon mail"></div>
                                            </div>
                                            
                                            <div className="content-input terms">
                                                <input
                                                {...checkableBoolProps("terms", "", form)}
                                                    type="checkbox"
                                                    id="term"
                                                    >
                                                </input>
                                                <label htmlFor="term" className="button-term"><div className="checked"></div></label>
                                                <label className={"text-term"} htmlFor="term">Acepto <strong><a target="_blank" href="Politica-de-privacidad-de-datos.pdf">términos y condiciones</a></strong></label>
                                            </div>
                                            <div className="content-input">
                                                <button className="contactar" type="submit" disabled={isSubmitting}>{isSubmitting ? "ENVIANDO..." : "REGÍSTRATE"}</button>
                                            </div>
                                        </div>
                                        <div className="message-whatsapp">
                                            <span className="title">
                                                Comunícate con un asesor aquí
                                            </span>
                                            <a href={this.props.url} target="_blank" className="content-icon-message">
                                                <div className="icon icon-whatsapp">

                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                                )
                                }}
                                </FormContainer>
                        )
                    }
                }
            </DataGlobal.Consumer>
            
        )
    }
}
