import React, { Component } from 'react';
import './politicasdeprivacidad.scss'
export default class Politicasdeprivacidad extends Component {
  render() {
    return (
        <section className="politicasdeprivacidad">
            <h1 className="title">POLÍTICAS DE PRIVACIDAD </h1>
            <div className='container'>
                <p className='paragraph'>
                Este documento recoge la Política de Privacidad del Grupo Padova e informa sobre las condiciones de tratamiento aplicables a los datos personales de posibles clientes e interesados en los productos o servicios del grupo, en cumplimiento de la Ley N° 29733, Ley de Protección de Datos Personales.
                </p>
                <p className='paragraph'>
                    Al revisar y aceptar los términos de esta política, usted se encuentra debidamente informado que sus datos personales, serán incorporados en el banco de datos denominado “Prospectos de Clientes” de titularidad de <span className='bold'>INVERSIONES PADOVA SAC.</span>, y podrán ser transferidos a una o más de sus empresas vinculadas, las cuales administran distintos proyectos inmobiliarios del Grupo Padova y se detallan en el Anexo, en función al(los) proyecto(s) de su interés (en adelante, “<span className='bold'>Grupo Padova</span>”). Las empresas antes mencionadas se encuentran domiciliadas en Jr. Las Poncianas 139 oficina 201, La Molina, Lima, Perú.
                </p>
                <p className='paragraph'>
                    Los datos personales que serán materia de tratamiento son los que usted nos proporcione mediante alguno de los formularios virtuales de las páginas web del Grupo Padova, mediante la función de “Chatbox” o “Chatea en Whatsapp”, por correo electrónico, por mensajería instantánea (SMS, Whatsapp u otro), en llamadas telefónicas, u otros canales de comunicación. Sus datos serán utilizados únicamente para: (i) dar cuenta de sus consultas sobre cotizaciones, así como atender cualquier otro requerimiento de información del usuario con relación a la oferta inmobiliaria; (ii) agendar una cita con un representante comercial; (iii) Atender las comunicaciones, quejas, requerimientos o incidentes; y (iv) en caso lo autorice expresamente, podremos realizar actividades de prospección comercial y de marketing, que incluyen informarle sobre las ofertas, promociones, beneficios, concursos y publicidad, así como participarle de encuestas de satisfacción, análisis de consumo, preferencias y tendencias de mercado. De no autorizar las actividades de prospección comercial y de marketing, éstas no se realizarán.
                </p>
                <p className='paragraph '>
                    El tratamiento de sus datos personales, siempre para las finalidades antes indicadas, será realizado directamente por <span className='bold'> Grupo Padova</span> y por terceros encargados que pueden encontrarse dentro o fuera del Perú:
                </p>

                <li>Fórmula Consultora de Marketing S.A.C., con domicilio en Av. G. Prada 565. dpto. 503, Magdalena del Mar, Lima, Perú, encargado de la administración de la página web.</li>
                <li>Microsoft Inc, ubicada en One Microsoft Way, Redmond, WA 98052-7329, Estados Unidos, que se encarga del respaldo de información.</li>
                <li>
                    DigitalOcean LLC, ubicado en 101 Avenue of the Americas, 10th Floor, New York, NY 10013, Estados Unidos, encargado de brindar el servicio de host de la página web.
                </li>
                <p className='paragraph'>Cualquier variación de los encargados de datos personales será comunicada a su correo electrónico y actualizada en este documento.</p>
                <p className='paragraph'>Finalmente, le informamos que: (i) <span className='bold'>Grupo Padova</span> garantiza la confidencialidad y el adecuado procesamiento de sus datos personales; (ii) el plazo de almacenamiento será aquél necesario para llevar a cabo las finalidades  antes indicadas, plazo que será como máximo [2 semanas],luego del cual sus datos personales serán eliminados; (iii) en caso autorice actividades de prospección comercial y de marketing el plazo de almacenamiento será indeterminado; y, (iv) podrá ejercer los derechos de acceso, rectificación, cancelación, oposición, información o revocación que por ley le asisten, remitiendo una comunicación al email: <a href="mailto:yleon@padovasac.com"> yleon@padovasac.com</a></p>
                <p className='paragraph text-list'>Empresas afiliadas a Grupo Padova en Perú: </p>
                <div className='d-flex content-list'>
                    <div className='list'>
                        <div className='d-flex header'>
                            <div className='item'>Empresa Vinculada</div>
                            <div className='item'>País</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Padova SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Mirano SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Anzio SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Majano SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Godia SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Ravena SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Azzano SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Forli SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Vasto SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Borgo SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                        <div className='d-flex'>
                            <div className='item'>Inversiones Saletti SAC</div>
                            <div className='item'>Perú</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}
