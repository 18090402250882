import React, { Component } from 'react'
import $ from 'jquery'
import './navbar.scss'
import { Loader } from '../loaderPage/loader'
import { NavLink } from 'react-router-dom'
const properties = {
		class: 'loader-page',
		icon: require('../../Assets/images/iconos/button-animation.json'),
		positionCss: 'relative',
		witdh: 250,
		height: 250,
		zIndex: 30,
	};
export default class Navbar extends Component {
    componentDidMount(){
        var optionsMov = [
            {section:".footer",signal:".Navbar",class:"",nav:-200,style:"transition:.2s;transform:scale(0)"},
        ]
        function DetectSecction(options){
            let element = document.querySelector(".Navbar") 
            if(element){
                options.forEach(option => {
                    if($(option.signal).offset().top > 0){
                        $(option.signal).addClass("active")
                    }else{
                        $(option.signal).removeClass("active")
                    }
                });
            }
        }    
        $(window).scroll(function(){
            DetectSecction(optionsMov);
        }); 
    }
    render() {
        return (
            <nav className="Navbar">
                <h1>
                    <img className="background-1 desktop" src={require("../../Assets/images/iconos/lomas-nav-right.png")} alt="LOMAS DE CARABAYLLO"></img>
                    </h1>
                <img className="background-1 movil" src={require("../../Assets/images/iconos/lomas-nav-right-movil.png")}></img>
                <img className="background-2 desktop" src={require("../../Assets/images/iconos/lomas-nav-center.png")}></img>
                <div className="container-nav container mx-auto">
                    <a href="/" className="first-brand">
                        <img className="desktop" src={require("../../Assets/images/iconos/lomas-desktop.svg").default}></img>
                        <img className="movil" src={require("../../Assets/images/iconos/brand-mobile.svg").default}></img>
                    </a>
                    <div className='d-flex'>
                        
                        <a href="https://padova.pe/" target="_blank"className="last-brand">
                            <img className="" src={require("../../Assets/images/iconos/lomas-white.svg").default}></img>
                            {/* <img className="" src={require("../../Assets/images/iconos/brand-padova.png")}></img> */}
                        </a>
                    </div>
                </div>
            </nav>
        )
    }
}
