import React, { Component, useContext, useState } from 'react'
import "./calculadora.scss";
import Select from 'react-select';
import {optionDorm,groupedOptions} from '../../pages/inicio/variables'
// import { FormikState } from 'formik';
import { FormContainer, setInputProps, setCurrencyInputProps, checkableBoolProps } from '../common/forms/Form';
import { BaseValidatonSchema, QuoterValidatonSchema } from '../common/forms/constraints/ValidatonSchemas';
import { MailService } from '../services/MailService';
import axios from 'axios';
import { InterestedEmail } from '../Mails/InterestedEmail';

const Calculadora = _ => {
    const [step, setStep] = useState(1)
    const [lead, setLead] = useState(null)
    const [quote, setQuote] = useState({})
    const [hasMultipleInvestors, setMultipleInvestors] = useState(false)
    const [whatsAppNumber, setWhatsAppNumber] = useState("https://web.whatsapp.com/send?phone=51998157192&text=Hola,%20deseo%20informaci%C3%B3n%20sobre%20el%20proyecto%20Lomas%20de%20Carabayllo")

    const mailService = new MailService()
    // constructor(props){
    //     super(props)
    //     state = {
    //         step:1
    //     }
    //     nextStep = nextStep.bind(this)
    //     selectStep = selectStep.bind(this)
    // }

    const nextStep = _ => {
        // e.preventDefault();
        setStep(step + 1)
    }

    const selectStep = (step) => {
        // e.preventDefault();
        setStep(step)
    }

    const firstStepInitialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        terms: false
    }

    const secondStepInitialValues = {
        people: null,
        price: null,
        initial_fee: null,
        salary: null,
        motive: null
    }

    const calculate = values => {
        let {price, initial_fee, salary, motive} = values
        initial_fee = parseInt(initial_fee.replace(/(,|\/|S)/g, ''))
        const initial_required = price.replace(/(,|\/|S)/g, '') * 0.1
        const actual_initial = parseFloat(initial_fee) + (0 * .25)
        console.log(price)
        // let amount_to_finance = price.replace(/(,|\/|S)/g, '') - Math.max(initial_required, actual_initial)
        // let amount_to_finance = price
        console.log(price)
        
        // BBP (Bono Buen Pagador)
        // Desde, Hasta, BBP Tradicional, BBP Sostenible
        const BBP = [
            [65200, 93100, 25700, 31100],
            [93100, 139400, 21400, 26800],
            [139400, 232200, 19600, 25000],
            [232200, 343900, 10800, 12700],
            // [343900, 464200, 0, 0],
        ]

        const subsidies = []

        for (const key in BBP) {
            if (Object.hasOwnProperty.call(BBP, key)) {
                const subsidy = BBP[key];
                const from = price > subsidy[0] ? subsidy[2] : 0 // [2] bbp // [3] verde / sostenible
                const to = price < subsidy[1] ? subsidy[2] : 0
                const sub = from === to ? from : 0
                subsidies.push(sub)
                // 147
                console.log(`Subsidio del estado ${parseInt(key) + 1}`, from, to, sub)
            }
        }

        const subsidy = motive == "Sí" ? subsidies.reduce((a, b) => a + b) : 0
        console.log("Subsidio total: ", subsidy)
        let amount_to_finance = (price - initial_fee ) - subsidy
        
        console.log("MONTO A FINANCIAR FINAL: ", amount_to_finance)
        console.log("BBP: ", subsidy)
    

        const getPeriodFee = (amount, tea, yearlyFees, years) => {  
            // Tipo de interés fraccionado (del periodo)
            const im = tea / yearlyFees / 100;
            const im2 = Math.pow((1 + im), -(yearlyFees * years))
            return ((amount * im) / (1 - im2)).toFixed(2) // Cuota Cap. + Int.
        }

        const cuota = getPeriodFee(amount_to_finance, 9.95, 12, 20)
        
        const isNegative = n => n < 0

        console.log("initial_fee", initial_fee)
        initial_fee = initial_fee >= initial_fee - subsidy ? initial_fee : Math.max(initial_required, actual_initial)
        console.log("fee ", initial_fee)
        initial_fee = !isNegative(initial_fee) ? initial_fee > price ? price - subsidy : initial_fee : 0
        console.log("fee", initial_fee, price, price - subsidy)

        if (!isNaN(cuota)) {
            const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

            values.observation = `Precio depa: ${values.price}, Inversores: ${values.people}, Inicial: ${values.initial_fee}, Ingreso mensual: ${values.salary}, primera vivienda: ${values.motive}, Cuota ref: ${cuota}, Califica: ${cuota <= parseFloat(values.salary.replace(/(,|\/|S)/g, '')) * .45 ? "Si" : "No"}`
            
            // mailService.from("Web Lomas <landing@lomasdecarabayllo.pe>")
            //     .to("padova_lomas_carabayllo@evolta.pe")
            //     .subject("Evolta lead")
            //     .html(InterestedEmail(values))
            
            // mailService.send()
            axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
                environment: "mirano",
                lead: {
                    IdProyecto: 65,
                    Nombres: values.fname,
                    Apellidos: values.lname,
                    Correo: values.email,
                    Celular: values.telephone,
                    Comentario: values.observation,
                    IdTipoPortal: 9,
                    url_query: localStorage.getItem('url_query')
                }
            })

            axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "14rEV56M4ujlxi-l3ABAoKCYIww_ejcuMhVRjaFxljEo",
                "range": `Calculadora!A:J`,
                "values": [[date, values.fname, values.lname, values.email, values.telephone,
                            values.price, values.people, values.initial_fee, values.salary, values.motive,
                            cuota, cuota <= parseFloat(values.salary.replace(/(,|\/|S)/g, '')) * .45 ? "Si" : "No"]],
            }, {headers: {"X-Requested-With": "XMLHttpRequest"}})

            setQuote({
                fee: formatCurrency(!isNegative(cuota) ? cuota : 0),
                initial_fee: formatCurrency(initial_fee),
                ... subsidy > 0 && {subsidy: formatCurrency(!isNegative(subsidy) ? subsidy : 0)}
            })
        }
    }

    const formatCurrency = (string) => string &&
            !isNaN(string.toString().replace(/(,|\/|S)/g, '')) &&
            parseFloat(string.toString().replace(/(,|\/|S)/g, ''))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    /**
     *
     * @param {FormikState} form
     */
    const submitLead = (values, { setSubmitting }) => {
        nextStep()
        if(!lead) {
            const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
            const [fromEmail, seller_phone] = sessionStorage.getItem("seller_phone").split(",")

            mailService.from(`Web Lomas <${fromEmail}>`)
                .to("padova_lomas_carabayllo@evolta.pe")
                .subject("Evolta lead")
                .html(InterestedEmail(values))
            
            new URLSearchParams(localStorage.getItem('url_query'))
                .forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v))

            axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                    "ss_id": "14rEV56M4ujlxi-l3ABAoKCYIww_ejcuMhVRjaFxljEo",
                    "range": `Web!A:E`,
                    "values": [[date, values.fname, values.lname, values.email, values.telephone, seller_phone, values.utm_source,
                                values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term]],
                }, {headers: {"X-Requested-With": "XMLHttpRequest"}}
            )
            // mailService.send()
            axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
                environment: "mirano",
                lead: {
                    IdProyecto: 65,
                    Nombres: values.fname,
                    Apellidos: values.lname,
                    Correo: values.email,
                    Celular: values.telephone,
                    Comentario: values.observation,
                    IdTipoPortal: 9,
                    url_query: localStorage.getItem('url_query')
                }
            })
            .then(r => {
                    setLead(values)
                    nextStep()
                    window.dataLayer && window.dataLayer.push({'event': 'LeadCalculadora'})
                    // setSubmitting(false)
                })
                .catch(e => {
                    alert("error")
                    // setSubmitting(false)
                })

            return
        }
    }

    /**
     *
     * @param {FormikState} form
     */
    const submitQuote = (values) => {

        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
        console.log(lead)
        Object.assign(values, lead)
        console.log(values)

        // values.observation = `¿En qué depa estás interesado?: ${values.price}, ¿Cuántas personas invertirán en el departamento?: ${values.people}, ¿Cuánto es el monto de la inicial que podría abonar?: ${values.initial_fee}, ¿Cuánto es tu ingreso mensual?: ${values.salary}, ¿Es tu primera vivienda?: ${values.motive}`
        
            // .then(r => {
            //     // setSubmitting(false)
            // })
            // .catch(e => {
            //     alert("error")
            //     // setSubmitting(false)
            // })

        calculate(values)
        nextStep()
    }

    return (
        <div className="calculator">
            <div className="steps">
                <div className="line-step"></div>
                <div className={`step ${step == 1 ? "active" : ""}`} onClick={(e)=>{selectStep(1)}}>
                    <span className="text-step">1</span>
                    <img className="img-step" src={require("../../Assets/images/iconos/img-step.png")}></img></div>
                <div className={`step ${step == 2 ? "active" : ""}`} onClick={(e)=>{lead && selectStep(2)}}><span className="text-step">2</span><img className="img-step" src={require("../../Assets/images/iconos/img-step.png")}></img></div>
                <div className={`step ${step == 3 ? "active" : ""}`} onClick={(e)=>{ Object.keys(quote).length && selectStep(3)}}><span className="text-step">3</span><img className="img-step" src={require("../../Assets/images/iconos/img-step.png")}></img></div>
            </div>
            <div className="content-forms">
                <FormContainer
                    initialValues={firstStepInitialValues}
                    validationSchema={BaseValidatonSchema}
                    onSubmit={submitLead}>
                    {form => {const {handleSubmit, isSubmitting} = form;
                    // console.log("step", step)
                    return(
                    <div className={`form-step form-step-1 flex-column ${step == 1 ? "active" : ""}`}>
                        <div className="content-title-form">
                            <span className="title-form">¡Hola! Ingresa tus datos para registrarte</span>
                        </div>
                        <form onSubmit={handleSubmit} className={`form-step form-step-1 flex-column ${step == 1 ? "active" : ""}`}>
                            
                            <input placeholder="Nombres*" {...setInputProps("fname", "", form)}></input>
                            <input placeholder="Apellidos*" {...setInputProps("lname", "", form)}></input>
                            <input placeholder="Teléfono*" {...setInputProps("telephone", "", form)}></input>
                            <input placeholder="Correo*" {...setInputProps("email", "", form)}></input>
                            <div className="content-input terms">
                                <input type="checkbox" id="term-cuota" {...checkableBoolProps("terms", " undefined", form)}></input>
                                <label for="term-cuota" class="button-term"><div class="checked"></div></label>
                                <label className={"text-term"} htmlFor="term">Acepto <strong><a target="_blank" href="Politica-de-privacidad-de-datos.pdf">términos y condiciones</a></strong></label>
                            </div>
                            <button type="submit" className="btn-submit">
                                <span className="text-submit">SIGUIENTE</span>
                                <div className="btn-signal-right"></div>
                            </button>
                            
                        </form>
                    </div>
                    )}}
                </FormContainer>
                <FormContainer
                    initialValues={secondStepInitialValues}
                    validationSchema={QuoterValidatonSchema}
                    onSubmit={submitQuote}>
                    {form => {const {errors, touched, handleSubmit, isSubmitting} = form;
                    setMultipleInvestors(form.values.people !== "1 Persona" && form.values.people !== "")
                    return(
                    <div  className={`form-step form-step-2 flex-column ${step == 2 ? "active" : ""}`}>
                        <div className="content-title-form">
                            <span className="title-form">Ahora es momento de cotizar tu depa</span>
                        </div>
                    
                    <form onSubmit={handleSubmit} className={`form-step form-step-2 flex-column ${step == 2 ? "active" : ""}`}>
                        <div className="row-step">
                            <span className="text-step">¿En qué depa estás interesado?</span>
                            <select {...setInputProps("price", "", form)}>
                                <option value="">№ de dormitorios</option>
                                <option value="159000">2 dormitorios - S/159,000</option>
                                <option value="173000">3 dormitorios - S/173,000</option>
                                <option value="264000">4 dormitorios - S/264,000</option>
                            </select>
                        </div>
                        <div className="row-step">
                            <span className="text-step">¿Cuántas personas invertirán en el departamento?</span>
                            <select {...setInputProps("people", "", form)} >
                                <option value="">Seleccionar</option>
                                <option>1 Persona</option>
                                <option>2 Personas</option>
                                <option>Más de 2 personas</option>
                            </select>
                        </div>
                        <div className="row-step">
                            <span className="text-step">¿Cuánto es el monto de la inicial que {hasMultipleInvestors ? "podrían": "podría" } abonar?</span>
                            <div className="flex-column">
                                <input placeholder="S/ 0,000*" {...setCurrencyInputProps("initial_fee", "", form)}></input>
                                {errors.initial_fee && touched.initial_fee ? (
                                    <><div className="error-msg">{errors.initial_fee}</div>
                                    <div className="icon-mask advice"></div></>
                                ) : null}
                            </div>
                        </div>
                        <div className="row-step">
                            <span className="text-step">¿Cuánto es {hasMultipleInvestors ? "su ingreso familiar": "tu ingreso" } mensual?</span>
                            <input placeholder="S/ 0,000*" {...setCurrencyInputProps("salary", "", form)}></input>
                        </div>
                        <div className="row-step">
                            <span className="text-step">¿Es {hasMultipleInvestors ? "su": "tu" } primera vivienda?</span>
                            <select {...setInputProps("motive", "", form)}>
                                <option value="">Seleccionar</option>
                                <option>Sí</option>
                                <option>No</option>
                            </select>
                        </div>
                        <button type="submit" className="btn-submit">
                            <span className="text-submit">CALCULAR</span>
                            <div className="btn-signal-right"></div>
                        </button>
                    </form>
                    </div>
                    )}}
                </FormContainer>
                <div className={`form-step form-step-2 calculate flex-column ${step == 3 ? "active" : ""}`}>
                    <h2 className="title">
                        <img className="background" src={require("../../Assets/images/iconos/background-genial.svg").default}></img>
                        ¡GENIAL!
                        <img className="title-left" src={require("../../Assets/images/iconos/title-left-step.png")}></img>
                        <img className="title-right" src={require("../../Assets/images/iconos/title-right-step.png")}></img>

                    </h2>
                    <span className="sub-title">HEMOS REALIZADO EL CÁLCULO DE TU CUOTA MENSUAL</span>
                    <div className="row-step">
                        <span className="text-step c-black">Considerando una inicial de:</span>
                        <span className="text-pay">S/ {quote.initial_fee}</span>
                    </div>
                    {quote.subsidy && <div className="row-step">
                        <span className="text-step c-black">Y un bono del buen pagador de:</span>
                        <span className="text-pay">S/ {quote.subsidy}<span className="exponente">(1)</span></span>
                    </div>}
                    <div className="row-step line">
                        <span className="text-step c-black">Tu cuota mensual será de:</span>
                        <span className="text-pay">S/ {quote.fee}<span className="exponente">(2)</span></span>
                    </div>
                    <p className="paragraph">(1) Consultar con el asesor de ventas los términos y condiciones del bono buen pagador.</p>
                    <p className="paragraph paragraph-2">(2) Cuota propuesta es referencial, sujeta a evaluación y aprobación del cliente por parte de la entidad financiera. </p>
                    <div className="row-step">
                        <button className="btn-calculate b-greenLight" onClick={ e => {selectStep(2,e)} }>RECALCULAR</button>
                        <a href={whatsAppNumber} className="btn-calculate b-Primary">CONTACTAR ASESOR</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Calculadora
