import MediaQuery from 'react-responsive';
import Slider from 'react-slick';

export const settings3 = {
    dots: true,
    centerMode: true,
    speed: 100,
    variableWidth: true,
    infinite:true,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 40
}
export default function Aliados(){
    return(
        <section className='section-aliados'>
            <img className='cloud-1' src={require('../../Assets/images/iconos/cloud-1.png')} ></img>
            <img className="cloud-left" src={require("../../Assets/images/iconos/cloud.png")}></img>
                    <img className="cloud-right" src={require("../../Assets/images/iconos/cloud.png")}></img>
            <img className='bg3-left' src={require('../../Assets/images/iconos/background-3-left.png')} ></img>
            <img className='bg3-right' src={require('../../Assets/images/iconos/background-3-right.png')} ></img>
            <div className='container mx-auto'>
                
                <div className='respaldo'>
                    <h2 className="text-center title mb-4 semibold">
                        <img className="title-left" src={require("../../Assets/images/iconos/title-left.png")}></img>
                        <img className="title-right" src={require("../../Assets/images/iconos/title-right.png")}></img>
                        ALIADOS ESTRATÉGICOS
                        <span className='light black'>
                        PARA CUMPLIR TUS SUEÑOS
                        </span>
                    </h2>
                    <div className='content-aliados'>
                        <MediaQuery query="(min-width:500px)">
                            <div className='aliados'>
                                <img className='aliado' src={require('../../Assets/images/inicio/slider/circle2.png')} ></img>
                            </div>
                            <div className='aliados'>
                                <img className='aliado' src={require('../../Assets/images/inicio/slider/fovime.svg').default} ></img>
                            </div>
                            <div className='aliados'>
                                <img className='aliado' src={require('../../Assets/images/inicio/slider/fovipol.svg').default} ></img>
                            </div>
                            <div className='aliados'>
                                <img className='aliado' src={require('../../Assets/images/inicio/slider/circle3.png')} ></img>
                            </div>
                        </MediaQuery>
                        <MediaQuery query="(max-width:500px)">
                            <Slider {...settings3}  className="Slider-characteristic">
                                
                                <div className='aliados'>
                                    <img className='aliado' src={require('../../Assets/images/inicio/slider/circle1.png')} ></img>
                                </div>
                                <div className='aliados'>
                                    <img className='aliado' src={require('../../Assets/images/inicio/slider/circle3.png')} ></img>
                                </div>
                                <div className='aliados'>
                                    <img className='aliado' src={require('../../Assets/images/inicio/slider/circle4.png')} ></img>
                                </div>
                                <div className='aliados'>
                                    <img className='aliado' src={require('../../Assets/images/inicio/slider/circle2.png')} ></img>
                                </div>
                            </Slider>
                        </MediaQuery>
                    </div>
                </div>
            </div>
        </section>
    )
}