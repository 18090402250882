import { useEffect, useState } from "react";
import { events, options } from "../inicio/variables";
import "./workProgress.scss"
import OwlCarousel from "react-owl-carousel2";
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import $ from 'jquery'

export const WorkProgresss = () => {
    const configModal = {
        selector: '.diap',
        thumbnail:true,
        animateThumb: false,
        showThumbByDefault: false,
        subHtmlSelectorRelative: true,
        rotate:false,
        actualSize:false,
        download:true,
        pinterest:false,
        googlePlus:false,
        twitter:true,
        enableDrag:true,

    }
    const data = [
        {
            name_slider:"Fortaleciendo las columnas de tu <span class='c-third'>nuevo hogar</span>",
            images:[
                {img:require("../../Assets/images/workprogress/slider-1/img-1.png")},
            ]
        },
        {
            name_slider:"Tus sueños ahora serán una <span class='c-third'>realidad</span>",
            images:[
                {img:require("../../Assets/images/workprogress/slider-2/img-1.png")},
               
            ]
        },
        {
            name_slider:"Pronto varias familias <span class='c-third'>formarán esta comunidad</span>",
            images:[
                {img:require("../../Assets/images/workprogress/slider-3/img-1.png")},
                        ]
        }
    ]
    const [state, setState] = useState(data)
    useEffect(()=>{
        $('.MyCarouselHorizontal').lightGallery(configModal);
        window.scrollTo(0,0)
    },[])
    return (
        <main className="workprogress">
            <div className='banner'>
                <img src={require("../../Assets/images/workprogress/compress-banner.webp")}></img>
                <img className='status' src={require('../../Assets/images/workprogress/modo-construccion.png')} ></img>
            </div>
            <div className='section-sliders'>
                <img className='left-top' src={require('../../Assets/images/workprogress/left-top-figure.png')} ></img>
                <img className='left-middle' src={require('../../Assets/images/workprogress/left-middle.png')} ></img>
                <img className='bottom' src={require('../../Assets/images/workprogress/bottom.png')} ></img>
                <img className='right-figure' src={require('../../Assets/images/workprogress/right-figure.png')} ></img>
                <div className='container'>
                    <div className='content-progress'>
                        <h1 className="title">AVANCE DE OBRA</h1>
                        {
                            state.map((slider,index)=>{ 
                                return(
                                <div className='section-progress'>
                                    <div className='content-text'>
                                        <div className='text' dangerouslySetInnerHTML={{__html:slider.name_slider}}>
                                        </div>
                                    </div>
                                    <div className="gallery-avance">
                                        <OwlCarousel options={options} events={events} className="MyCarouselHorizontal">
                                        {
                                                slider.images.map((e,index)=>{
                                                    return(
                                                        <div className="diap" key={"item-working"+index} data-src={e.img} href={e.img} data-sub-html={"#working-"+index}>
                                                            <div className="lupa">
                                                                <div className="icon-lupa"></div>
                                                            </div>
                                                            <img className="img" src={e.img} alt="Avance de obra"></img>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </OwlCarousel>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}