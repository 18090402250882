import $ from 'jquery'
export const options = {
    items: 2,
    center:true,
    rewind: true,
    loop: false,
    nav: true,
    // smartSpeed:700,
    variableWidth: true,
    dots: true,
    autoWidth: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.36 27.07"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="14.36 25.07 2.83 13.53 14.36 2" fill="none" stroke="#00878a" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.36 27.07"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="2 2 13.53 13.53 2 25.07" fill="none" stroke="#00878a" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/></g></g></svg>'
    ],
};

export const optionsAtributos = {
    responsive:{
        0:{
            items:5,
        },
        600:{
            items:5,
        }
    },
    center:true,
    rewind: true,
    loop:true,
    nav:true,
    smartSpeed:700,
    variableWidth: true,
    dots:true,
    autoWidth:true,
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.36 27.07"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="14.36 25.07 2.83 13.53 14.36 2" fill="none" stroke="#00878a" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.36 27.07"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="2 2 13.53 13.53 2 25.07" fill="none" stroke="#00878a" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/></g></g></svg>'
    ],
    
}

export const eventsInicio = {
    onChanged: function(e){
        // clearInterval(rotateSlider);
        // rotateSlider = setInterval(() => {
        //     $('.carousel-inicio').trigger('next.owl.carousel',1000)
        // }, 7000);
    }
};
export const events = {
    onChanged: function(e){
    }
};

export const optionsInicio = {
    items: 2,
    center:false,
    rewind: true,
    loop:true,
    nav:true,
    autoplay:true,
    smartSpeed:1000,
    autoplayTimeout:7000,
    variableWidth: true,
    dots:true,
    autoWidth:true,
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.36 27.07"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="14.36 25.07 2.83 13.53 14.36 2" fill="none" stroke="#00878a" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.36 27.07"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="2 2 13.53 13.53 2 25.07" fill="none" stroke="#00878a" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/></g></g></svg>'
    ],
};

export const configModal = {
    selector: '.diap',thumbnail:true,animateThumb: false,showThumbByDefault: false,subHtmlSelectorRelative: true,rotate:false,actualSize:false,download:true,pinterest:false,googlePlus:false,twitter:true,enableDrag:true,}


export const afterbotton = {origin:"bottom",duration:1800,distance:"120px",delay:300,reset:false,easing:"ease-out",
    mobile:false,
    // afterReveal:event,
}
export const afterbotton2 = {origin:"bottom",duration:1700,distance:"150px",delay:700,reset:false,mobile:false,easing:"ease-out"}

export const titleleft = {delay:1000,reset:false,easing:"ease-out",
                    afterReveal:function myCallback (el) {
                            el.classList.remove('active');
                            el.classList.add('active');
                        },
                    }
var contador = 0;
export const settings2 = {
    dots: true,
    centerMode: true,
    speed: 100,
    variableWidth: true,
    infinite:true,
    slidesToShow: 2,
    slidesToScroll: 1,
    margin: 40,
    beforeChange: function(current, next){
        // console.log("this current: ",current)
        // console.log("this next: ", next)
        var cont1 = current+1;
        var cont2 = next+1;

        console.log("this current: ",cont1)
        console.log("this next: ", cont2)

        if(cont1 == 1 && cont2 == 2){
            toLeft()
        }
        if(cont1 == 2 && cont2 == 3){
            toLeft()
        }
        if(cont1 == 3 && cont2 == 4){
            toLeft()
        }
        if(cont1 == 4 && cont2 == 5){
            toLeft()
        }
        if(cont1 == 5 && cont2 == 1){
            toLeft()
        }
        if(cont1 == 1 && cont2 == 5){
            toRight()
        }
        if(cont1 == 5 && cont2 == 4){
            toRight()
        }
        if(cont1 == 4 && cont2 == 3){
            toRight()
        }
        if(cont1 == 3 && cont2 == 2){
            toRight()
        }
        if(cont1 == 2 && cont2 == 1){
            toRight()
        }

        function toLeft(){
            if(contador == 0){
                $(".img-1").addClass("none")
                contador++
                console.log(contador)
            }else{
                $(".img-0").addClass("none")
                console.log(contador)
                contador++
                $(".img").removeClass("none")
                $(".img-0").addClass("none")
            }
            $(`.content-svg .img`).removeClass("moveleft")
            $(`.content-svg .img`).removeClass("moveright")
            $(`.content-svg .img`).removeClass("moveleft2")
            $(`.content-svg .img`).removeClass("moveright2")
            
            $(`.img-${cont1}`).addClass("moveleft")
            $(`.img-${cont2}`).addClass("moveright")
            
            $(".img-0").addClass("moveleft")
        }
        function toRight(){
            contador++
            if(contador == 0){
                $(".img-1").addClass("none")
                contador=1
                console.log(contador)
            }else{
                $(".img-0").addClass("none")
                console.log(contador)
                contador++
                $(".img").removeClass("none")
                $(".img-0").addClass("none")
            }
            $(`.content-svg .img`).removeClass("moveleft")
            $(`.content-svg .img`).removeClass("moveright")
            $(`.content-svg .img`).removeClass("moveleft2")
            $(`.content-svg .img`).removeClass("moveright2")
            
            $(`.img-${cont2}`).addClass("moveleft2")
            $(`.img-${cont1}`).addClass("moveright2")
            
            $(".img-0").addClass("moveright2")
        }
    },
};

export const settings1 = {
    dots: true,
    centerMode: true,
    speed: 100,
    variableWidth: true,
    infinite:false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 7000,
    afterChange:function(e){
        
    }
}

export const comun = [
    {
      title1:"Lobby",title2:"Imagen de lujo"
    }
]
export const optionDorm = [
    { value: '', label: 'N de dormitorios', isFixed: true },
    { value: '1', label: '1 Dormitorio', isFixed: true },
    { value: '2', label: '2 Dormitorios'},
    { value: '3', label: '3 Dormitorios '},
];
export const groupedOptions = [
    {
        // label: 'Dormitorios',
        options: optionDorm,
    }
];
    
   