import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string()
        .required(),
    lname: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
}

const formatCurrency = (val) => !isNaN(val.toString().replace(/,/g, '')) &&
            parseFloat(val.toString().replace(/,/g, ''))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

Yup.addMethod(Yup.string, "moreThanPercent", function (price, percent, errorMessage) {
    return this.test(`test-initial-type`, errorMessage, function (value) {
        const { path, createError } = this;
        value = value.replace(/(,|\/|S)/g, '')
        const pricePercent = price * .1
        console.log(pricePercent)
        return (
            value >= pricePercent
            || createError({ path, message: `Debe ser mayor a S/${formatCurrency(pricePercent)}` })
        )
    })
})

export const BaseValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const QuoterValidatonSchema = (values) => Yup.object()
    .shape({
        people: Yup.string(),
        price: Yup.string(),
        initial_fee: Yup.string()
        // .when(['price'], price => Yup.string().moreThanPercent(values.price, .1))
        .when('price', {
            is: (price) => price > 0,
            then: Yup.string().moreThanPercent(values.price, .1).typeError(' '),
            otherwise: Yup.string().required(' ').nullable()
        }),
        // afp_savings: Yup.string(),
        salary: Yup.string()
            .required(),
        motive: Yup.string()
            .required()
    })