import React, { Component } from 'react'
import MediaQuery from 'react-responsive';
import Slider from "react-slick";
import { settings2} from '../../pages/inicio/variables'
import $ from 'jquery'

let rotateSlider;
var timeUniversal;
export default class SliderIcons extends Component {
    constructor(props){
        super(props)
        this.itemHover = this.itemHover.bind(this)
        this.itemHoverSlider = this.itemHoverSlider.bind(this)
        this.itemHoverActive = this.itemHoverActive.bind(this)
        this.itemHoverLeave = this.itemHoverLeave.bind(this)
        this.state = {
            hover:true,
            count:0,
            time:0,
        }

        console.log(props)
    }
    itemHoverSlider(e){
        this.itemHover(e)
    }
    itemHoverActive(e){
        clearInterval(timeUniversal)
        this.setState({count:e})
        this.itemHover(e)
    }
    itemHover(e,element){
        $(`.content-atributos .content-items .item`).removeClass("active")
        $(`.content-atributos .content-items .item-${e}`).addClass("active")
        if(this.state.hover == true){
            activeHover()
        }
        function activeHover(){
            
            if($(`.img-${e+1}`).hasClass("active") == false){
                $(".img").removeClass("hover")
                $(`.content-atributos .img-${e+1}`).addClass("hover")
                let active = document.querySelector(`.content-atributos .img.active`)
                if(active){
                    $(".img").removeClass("move")
                    active.classList.add("move")
                    $(".img").removeClass("active")
                    $(".img").removeClass("active-img")
                    $(`.img-${e+1}`).addClass("active")
                }   
            }
        }
    }
    itemHoverLeave(e){
        $(".img.active").removeClass("move")
        this.setState({time:clearInterval(this.state.time)})
        clearInterval(timeUniversal)
        timeUniversal = setInterval(() => {
            console.log(this.state.count)
            this.setState({count:this.state.count == 5 ? this.state.count = 0 : this.state.count+1})
            this.itemHoverSlider(this.state.count)
        }, 5000)
    }
    render() {
        return (
            <div className='w-full'>
                <section className="atributos container mx-auto">
                    <img className="b-1" src={require("../../Assets/images/iconos/b-1.png")}></img>
                    <img className="b-2" src={require("../../Assets/images/iconos/background-2.png")}></img>
                    <img className="b-3" src={require("../../Assets/images/iconos/background-3.png")}></img>
                    <h2 className="text-center title semibold">
                        <img className="title-left" src={require("../../Assets/images/iconos/title-left.png")}></img>
                        <img className="title-right" src={require("../../Assets/images/iconos/title-right.png")}></img>

                        ¿POR QUÉ VIVIR EN LOMAS DE CARABAYLLO?
                    </h2>
                    <div className="content-atributos">
                        
                        <div className="content-items">
                            <MediaQuery query="(min-width:740px)">
                            {
                                this.props.items.items.map((e,index)=>{
                                    return(
                                        <div className={`item item-${index}`}
                                        key={"item"+index} 
                                        onMouseEnter={(e)=>{this.itemHoverActive(index)}}
                                        onMouseLeave={()=>{this.itemHoverLeave(index)}}
                                        onClick={()=>{this.itemActive(index)}}
                                        >
                                            <div className="img"><img src={e.img.default}></img></div>
                                            <div className="title">
                                                <p className="t-title">{e.title}</p>
                                                <p className="sub-title">{e.subtitle}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </MediaQuery>
                            <MediaQuery query="(max-width:740px)">
                                <Slider {...settings2}  className="Slider-characteristic">
                                {
                                    this.props.items.itemsMovil.map((e,index)=>{
                                        return(
                                            <div className="item" key={"item-movil"+index}>
                                                <div className="img">
                                                    <img src={e.img}></img>
                                                </div>
                                                {/* <div className="title">{e.title}</div> */}
                                            </div>
                                        )
                                    })
                                }
                                </Slider>
                            </MediaQuery>
                        </div>
                    </div>
                    <div className='button-brochure'>
                    </div>
                </section>
            </div>
        )
    }
}
